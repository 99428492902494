tippy("[data-tippy-content]", {
  allowHTML: true,
  arrow: false,
  maxWidth: 287,
});

const tooltip = document.querySelector(".tooltip");
if (tooltip) {
  tippy(".tooltip .tooltip__button", {
    content(reference) {
      const template = reference.nextElementSibling;
      return template.innerHTML;
    },
    placement: "bottom-start",
    allowHTML: true,
    animation: "scale",
    arrow: false,
    maxWidth: 330,
    inlinePositioning: true,
    // interactive: true,
    interactiveDebounce: 75,
  });
}
